import type { Currency } from "@prisma/client";
import type Stripe from "stripe";
import { env } from "@/env.mjs";
import { type SubscriptionPlan } from "@prisma/client";
import { type TCountryCode } from "countries-list";
import { z } from "zod";

export type StripeSubscriptionWithPlanAndMeta = Stripe.Subscription & {
  plan?: Stripe.Plan;
  metadata: StripeMetadata;
  quantity: number;
}; // so far this seems to be present on all events received by this webhook

export const stripeDict = {
  lightOld: {
    lookupKey: "old_light",
    plan: env.NEXT_PUBLIC_STRIPE_OLD_LIGHT_PLAN_PRICE_ID,
  },
  starterOld: {
    lookupKey: "old_starter",
    plan: env.NEXT_PUBLIC_STRIPE_OLD_STARTER_PLAN_PRICE_ID,
  },
  growthOld: {
    lookupKey: "old_growth",
    plan: env.NEXT_PUBLIC_STRIPE_OLD_GROWTH_PLAN_PRICE_ID,
  },
  customOld: {
    lookupKey: "custom_old",
    plan: env.NEXT_PUBLIC_STRIPE_OLD_ENTERPRISE_PLAN_PRICE_ID,
  },
  internationalMonthlyOld: {
    lookupKey: "old_interational_weekly",
    plan: env.NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_MONTHLY_PLAN_PRICE_ID,
  },
  internationalYearlyOld: {
    lookupKey: "old_international_yearly",
    plan: env.NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_YEARLY_PLAN_PRICE_ID,
  },
  // NEW PLANS
  core: {
    lookupKey: "core",
    plan: env.NEXT_PUBLIC_STRIPE_CORE_PLAN_PROD_ID,
  },
  growth: {
    lookupKey: "growth",
    plan: env.NEXT_PUBLIC_STRIPE_GROWTH_PLAN_PROD_ID,
  },
  pro: {
    lookupKey: "pro",
    plan: env.NEXT_PUBLIC_STRIPE_PRO_PLAN_PROD_ID,
  },
};

export type SubscriptionPeriod = "MONTHLY" | "YEARLY" | "SEMI_ANNUALLY";
export type SubscriptionCurrency = "EUR" | "NOK";

export const zSubscriptionPeriod = z.enum(["MONTHLY", "YEARLY", "SEMI_ANNUALLY"]);
export const zSubscriptionCurrency = z.enum(["EUR", "NOK"]);

export const planToPrice: {
  [key in SubscriptionPlan]:
    | {
        priceId: string;
        maxListings: number;
        // TODO respect different currencies
        pricePrMonth: number;
        successFee: number;
        successPercentage: number;
        currency: Currency;
        _isOldPlan: true;
      }
    | {
        prodId: string;
        maxListings: number;
        aiCredits: number;
        vouchCount: number;
        pricing: {
          period: SubscriptionPeriod;
          currency: SubscriptionCurrency;
          additionalVouchPrice: number;
        }[];
        successPercentage: number;
        _isOldPlan: false;
      };
} = {
  LIGHT: {
    priceId: stripeDict.lightOld.plan,
    maxListings: 1,
    pricePrMonth: 0,
    successFee: 30_000,
    currency: "NOK",
    successPercentage: 0,
    _isOldPlan: true,
  },
  STARTER: {
    priceId: stripeDict.starterOld.plan,
    maxListings: 2,
    pricePrMonth: 2_000,
    successFee: 20_000,
    currency: "NOK",
    successPercentage: 0,
    _isOldPlan: true,
  },
  GROWTH_OLD: {
    priceId: stripeDict.growthOld.plan,
    maxListings: 6,
    pricePrMonth: 4_800,
    successFee: 10_000,
    currency: "NOK",
    successPercentage: 0,
    _isOldPlan: true,
  },
  CUSTOM: {
    priceId: stripeDict.customOld.plan,
    maxListings: 20,
    pricePrMonth: 10_000,
    successFee: 0,
    currency: "NOK",
    successPercentage: 0,
    _isOldPlan: true,
  },
  INTERNATIONAL_MONTHLY: {
    priceId: stripeDict.internationalMonthlyOld.plan,
    maxListings: 1000,
    pricePrMonth: 89.99,
    successFee: 0,
    currency: "EUR",
    successPercentage: 0.25,
    _isOldPlan: true,
  },
  INTERNATIONAL_YEARLY: {
    priceId: stripeDict.internationalYearlyOld.plan,
    maxListings: 1000,
    pricePrMonth: 799.99,
    successFee: 0,
    currency: "EUR",
    successPercentage: 0.25,
    _isOldPlan: true,
  },
  // new
  CORE: {
    _isOldPlan: false,
    aiCredits: 100,
    vouchCount: 25,
    maxListings: 1000,
    successPercentage: 0.275,
    prodId: stripeDict.core.plan,
    pricing: [
      {
        currency: "NOK",
        period: "MONTHLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "NOK",
        period: "SEMI_ANNUALLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "NOK",
        period: "YEARLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "EUR",
        period: "MONTHLY",
        additionalVouchPrice: 1,
      },
      {
        currency: "EUR",
        period: "SEMI_ANNUALLY",
        additionalVouchPrice: 1,
      },
      {
        currency: "EUR",
        period: "YEARLY",
        additionalVouchPrice: 1,
      },
    ],
  },
  GROWTH: {
    _isOldPlan: false,
    maxListings: 1000,
    successPercentage: 0.275,
    prodId: stripeDict.growth.plan,
    aiCredits: 400,
    vouchCount: 200,
    pricing: [
      {
        currency: "NOK",
        period: "MONTHLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "NOK",
        period: "SEMI_ANNUALLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "NOK",
        period: "YEARLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "EUR",
        period: "MONTHLY",
        additionalVouchPrice: 1,
      },
      {
        currency: "EUR",
        period: "SEMI_ANNUALLY",
        additionalVouchPrice: 1,
      },
      {
        currency: "EUR",
        period: "YEARLY",
        additionalVouchPrice: 1,
      },
    ],
  },
  PRO: {
    // same as GROWTH FOR NOW!
    _isOldPlan: false,
    maxListings: 1000,
    successPercentage: 0.275,
    prodId: stripeDict.pro.plan,
    aiCredits: 400,
    vouchCount: 200,
    pricing: [
      {
        currency: "NOK",
        period: "MONTHLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "NOK",
        period: "SEMI_ANNUALLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "NOK",
        period: "YEARLY",
        additionalVouchPrice: 10,
      },
      {
        currency: "EUR",
        period: "MONTHLY",
        additionalVouchPrice: 1,
      },
      {
        currency: "EUR",
        period: "SEMI_ANNUALLY",
        additionalVouchPrice: 1,
      },
      {
        currency: "EUR",
        period: "YEARLY",
        additionalVouchPrice: 1,
      },
    ],
  },
};

export const isOldPlan = (plan: SubscriptionPlan) => {
  return planToPrice[plan]._isOldPlan;
};

export interface StripeMetadata {
  companyId: string;
  companyName: string;
  [key: string]: string;
}

export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value;

  let stringified = "[Unable to stringify the thrown value]";
  try {
    stringified = JSON.stringify(value);
  } catch {
    stringified = "";
  }

  const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`);
  return error;
}

export type StripeFeedback =
  | "customer_service"
  | "low_quality"
  | "missing_features"
  | "other"
  | "switched_service"
  | "too_complex"
  | "too_expensive"
  | "unused";

export function isStripeFeedback(value: string): value is StripeFeedback {
  return [
    "customer_service",
    "low_quality",
    "missing_features",
    "other",
    "switched_service",
    "too_complex",
    "too_expensive",
    "unused",
  ].includes(value);
}

export function getTaxIdTypeAndNumber(
  country: TCountryCode,
  taxId: string,
): {
  taxIdType: Stripe.TaxId.Type;
  formatedTaxId: string;
} {
  switch (country) {
    case "NO":
      const formattedNOTaxId = taxId.includes("MVA") ? taxId : taxId + "MVA";
      return {
        taxIdType: "no_vat",
        formatedTaxId: formattedNOTaxId,
      };
    case "AU":
      return {
        taxIdType: "au_abn",
        formatedTaxId: taxId,
      };
    case "GB":
      return {
        taxIdType: "gb_vat",
        formatedTaxId: taxId,
      };
    case "US":
      return {
        taxIdType: "us_ein",
        formatedTaxId: taxId,
      };
    case "SE":
      const formattedSETaxId = taxId.includes("SE") ? taxId : "SE" + taxId.replace("-", "") + "01";
      return {
        taxIdType: "eu_vat",
        formatedTaxId: formattedSETaxId,
      };
    default: // SE, DK, DE
      return {
        taxIdType: "eu_vat",
        formatedTaxId: taxId,
      };
  }
}

export function convertStripeDate(stripeDate: number | null): Date | null {
  if (!stripeDate) return null;
  return new Date(stripeDate * 1000);
}
