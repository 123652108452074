import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    DATABASE_POOLING_URL: z.string().url(),
    DATABASE_DIRECT_URL: z.string().url(),
    NODE_ENV: z.enum(["development", "test", "production"]).default("development"),
    NEXTAUTH_SECRET: process.env.NODE_ENV === "production" ? z.string().min(1) : z.string().min(1),
    NEXTAUTH_URL: z.preprocess(
      //   // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      //   // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => process.env.VERCEL_URL ?? str,
      //   // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string().min(1) : z.string().url(),
    ),
    // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
    GITHUB_CLIENT_ID: z.string(),
    GITHUB_CLIENT_SECRET: z.string(),
    LINKEDIN_CLIENT_ID: z.string(),
    LINKEDIN_CLIENT_SECRET: z.string(),

    QSTASH_TOKEN: z.string(),
    QSTASH_CURRENT_SIGNING_KEY: z.string(),
    QSTASH_NEXT_SIGNING_KEY: z.string(),

    INTERCOM_APP_TOKEN: z.string(),
    // this is a tag for "Newsletter" in Intercom
    INTERCOM_NEWSLETTER_GROUP: z.string(),
    INTERCOM_TAG_CANDIDATE: z.string(),
    INTERCOM_TAG_COMPANY_CONTACT: z.string(),
    INTERCOM_TAG_VOUCHER: z.string(),
    INTERCOM_TAG_HIRED: z.string(),
    SKIP_RETOOL: z
      .string()
      .default("true")
      .transform((str) => str === "true"),

    SUPABASE_URL: z.string().url(), // might need to turn this into NEXT_PUBLIC, I think that would be fine
    SUPABASE_API_KEY: z.string(), // this one could also be NEXT_PUBLIC
    SUPABASE_API_KEY_PRIVILEGED: z.string(), // THIS ONE SHOULD NEVER BE NEXT_PUBLIC

    SYNC_INTERCOM: z
      .string()
      .default("false")
      .transform((str) => str === "true"),

    SENDGRID_API_KEY: z.string(),
    TWILIO_AUTH_KEY: z.string(),

    NOTION_TOKEN: z.string(),
    SYNC_SLACK: z
      .string()
      .optional()
      .transform((str) => str === "true"),
    NOTION_MARKETING_OF_NEW_JOB_POSTS_DATABASE_ID: z.string().optional(),
    NOTION_NEW_HIRE_DATABASE_ID: z.string().optional(),
    VERCEL_URL: z.preprocess(
      (str) => str ?? process.env.NEXTAUTH_URL,
      process.env.VERCEL ? z.string().min(1) : z.string().url(),
    ),
    GOOGLE_VERTEXT_AI_CREDENTIALS: z.string(),
    AUDIENCEFUL_API_KEY: z.string().optional(),
    STRIPE_API_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
    TZ: z.string().default("UTC"),
    LIMIT_TO_AUTH_WHITELIST: z.boolean().optional(),
    SENTRY_AUTH_TOKEN: z.string().optional(), // TODO make non optional

    VECTOR_STORE_DB_URL: z.string().optional(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
    NEXT_PUBLIC_APP_BASE_URL: z.string().url(),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
    NEXT_PUBLIC_LANG: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().optional(),
    NEXT_PUBLIC_SANITY_PROJECT_ID: z.string(),
    NEXT_PUBLIC_SANITY_DATASET: z.string(),
    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: z.string().optional(),
    NEXT_PUBLIC_LINKEDIN_PARTNER_ID: z.string().optional(),
    NEXT_PUBLIC_STRIPE_PUBLIC_KEY: z.string(),
    NEXT_PUBLIC_GA_MEASUREMENT_ID: z.string().optional(),
    NEXT_PUBLIC_HOTJAR_SITE_ID: z.string().optional(),

    NEXT_PUBLIC_STRIPE_OLD_LIGHT_PLAN_PRICE_ID: z.string(),
    NEXT_PUBLIC_STRIPE_OLD_STARTER_PLAN_PRICE_ID: z.string(),
    NEXT_PUBLIC_STRIPE_OLD_GROWTH_PLAN_PRICE_ID: z.string(),
    NEXT_PUBLIC_STRIPE_OLD_ENTERPRISE_PLAN_PRICE_ID: z.string(),
    NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_MONTHLY_PLAN_PRICE_ID: z.string(),
    NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_YEARLY_PLAN_PRICE_ID: z.string(),

    NEXT_PUBLIC_STRIPE_CORE_PLAN_PROD_ID: z.string(),
    NEXT_PUBLIC_STRIPE_GROWTH_PLAN_PROD_ID: z.string(),
    NEXT_PUBLIC_STRIPE_PRO_PLAN_PROD_ID: z.string(),
  },
  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    DATABASE_POOLING_URL: process.env.DATABASE_POOLING_URL,
    DATABASE_DIRECT_URL: process.env.DATABASE_DIRECT_URL,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_APP_BASE_URL: process.env.NEXT_PUBLIC_APP_BASE_URL,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    VERCEL_URL: process.env.VERCEL_URL,
    GITHUB_CLIENT_ID: process.env.GH_CLIENT_ID || process.env.GITHUB_CLIENT_ID,
    GITHUB_CLIENT_SECRET: process.env.GH_CLIENT_SECRET || process.env.GITHUB_CLIENT_SECRET,
    LINKEDIN_CLIENT_ID: process.env.LINKEDIN_CLIENT_ID,
    LINKEDIN_CLIENT_SECRET: process.env.LINKEDIN_CLIENT_SECRET,

    QSTASH_TOKEN: process.env.QSTASH_TOKEN,
    QSTASH_CURRENT_SIGNING_KEY: process.env.QSTASH_CURRENT_SIGNING_KEY,
    QSTASH_NEXT_SIGNING_KEY: process.env.QSTASH_NEXT_SIGNING_KEY,

    NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    INTERCOM_APP_TOKEN: process.env.INTERCOM_APP_TOKEN,

    SKIP_RETOOL: process.env.SKIP_RETOOL, // TODO REmove

    SUPABASE_URL: process.env.SUPABASE_URL,
    SUPABASE_API_KEY: process.env.SUPABASE_API_KEY,
    SUPABASE_API_KEY_PRIVILEGED: process.env.SUPABASE_API_KEY_PRIVILEGED,

    SYNC_INTERCOM: process.env.SYNC_INTERCOM,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    TWILIO_AUTH_KEY: process.env.TWILIO_AUTH_KEY,
    INTERCOM_NEWSLETTER_GROUP: process.env.INTERCOM_NEWSLETTER_GROUP,
    INTERCOM_TAG_CANDIDATE: process.env.INTERCOM_TAG_CANDIDATE,
    INTERCOM_TAG_COMPANY_CONTACT: process.env.INTERCOM_TAG_COMPANY_CONTACT,
    INTERCOM_TAG_VOUCHER: process.env.INTERCOM_TAG_VOUCHER,
    INTERCOM_TAG_HIRED: process.env.INTERCOM_TAG_HIRED,

    NOTION_TOKEN: process.env.NOTION_TOKEN,
    NOTION_MARKETING_OF_NEW_JOB_POSTS_DATABASE_ID:
      process.env.NOTION_MARKETING_OF_NEW_JOB_POSTS_DATABASE_ID,
    NOTION_NEW_HIRE_DATABASE_ID: process.env.NOTION_NEW_HIRE_DATABASE_ID,

    NEXT_PUBLIC_LANG: process.env.NEXT_PUBLIC_LANG,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,

    SYNC_SLACK: process.env.SYNC_SLACK,
    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,

    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    NEXT_PUBLIC_LINKEDIN_PARTNER_ID: process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID,

    AUDIENCEFUL_API_KEY: process.env.AUDIENCEFUL_API_KEY,

    STRIPE_API_SECRET_KEY: process.env.STRIPE_API_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
    TZ: process.env.TZ,

    LIMIT_TO_AUTH_WHITELIST: process.env.LIMIT_TO_AUTH_WHITELIST ? true : false,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_HOTJAR_SITE_ID: process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,

    GOOGLE_VERTEXT_AI_CREDENTIALS: process.env.GOOGLE_VERTEXT_AI_CREDENTIALS,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,

    NEXT_PUBLIC_STRIPE_OLD_LIGHT_PLAN_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_OLD_LIGHT_PLAN_PRICE_ID,
    NEXT_PUBLIC_STRIPE_OLD_STARTER_PLAN_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_OLD_STARTER_PLAN_PRICE_ID,
    NEXT_PUBLIC_STRIPE_OLD_GROWTH_PLAN_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_OLD_GROWTH_PLAN_PRICE_ID,
    NEXT_PUBLIC_STRIPE_OLD_ENTERPRISE_PLAN_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_OLD_ENTERPRISE_PLAN_PRICE_ID,
    NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_MONTHLY_PLAN_PRICE_ID:
      process.env.NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_MONTHLY_PLAN_PRICE_ID,
    NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_YEARLY_PLAN_PRICE_ID:
      process.env.NEXT_PUBLIC_STRIPE_OLD_INTERNATIONAL_YEARLY_PLAN_PRICE_ID,

    NEXT_PUBLIC_STRIPE_PRO_PLAN_PROD_ID:
      process.env.NEXT_PUBLIC_STRIPE_PRO_PLAN_PROD_ID,
    NEXT_PUBLIC_STRIPE_GROWTH_PLAN_PROD_ID:
      process.env.NEXT_PUBLIC_STRIPE_GROWTH_PLAN_PROD_ID,
    NEXT_PUBLIC_STRIPE_CORE_PLAN_PROD_ID:
      process.env.NEXT_PUBLIC_STRIPE_CORE_PLAN_PROD_ID,


    VECTOR_STORE_DB_URL: process.env.VECTOR_STORE_DB_URL,
  },

  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined.
   * `SOME_VAR: z.string()` and `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});

// export const isProd = env.DATABASE_POOLING_URL.includes("vouch-prod-do-user"); // ! update if we ever change the database url // or use a hard coded env variable
export const isProd = process.env.DATABASE_POOLING_URL?.includes("vouch-prod-do-user");
export const isDev = process.env.NODE_ENV !== "production";
